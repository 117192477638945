import { useEffect, useState } from 'react'

import { NextIntlClientProvider } from 'next-intl'
import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import Head from 'next/head'
import { useRouter } from 'next/router'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import PreLoader from '@/components/PreLoader'

import '@/assets/css/global.css'

import 'swiper/css'

const rogan = localFont({
  src: [
    {
      path: '../assets/fonts/Rogan-Thin.ttf',
      weight: '100',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-ExtraLight.ttf',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../assets/fonts/Rogan-ExtraBold.ttf',
      weight: '800',
      style: 'normal',
    },
  ],
})

export default function App({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])

  const router = useRouter()

  return (
    <>
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale={router.locale}
      >
        <style jsx global>{`
          html {
            font-family: ${rogan.style.fontFamily};
          }
        `}</style>
        <Head>
          <title>Pi-ma! - Tasma Vending Machine</title>
        </Head>
        <PreLoader loading={loading} />
        <main className={rogan.className}>
          <Component {...pageProps} />
        </main>
      </NextIntlClientProvider>
    </>
  )
}
