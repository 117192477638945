import Image from 'next/image'

import { joinClassnames } from '@/utils/common'

const PreLoader = ({ loading }: { loading: boolean }) => {
  return (
    <div
      className={joinClassnames([
        'fixed left-0 top-0 z-50 flex max-h-screen min-h-screen w-screen flex-col items-center justify-center overflow-hidden bg-black duration-200',
        !loading ? '-translate-y-full' : '',
      ])}
    >
      <Image
        src="/images/loading-screen.gif"
        alt="animated-loading"
        width={80}
        height={80}
        sizes="100vw"
        loading="lazy"
      />
    </div>
  )
}

export default PreLoader
